import { datalabApi } from '@cmg/api';
import { apiUtil } from '@cmg/common';
import { addWeeks, addYears, previousSaturday, subWeeks, subYears } from 'date-fns';
import saveAs from 'file-saver';
import uniq from 'lodash/uniq';

import {
  type OfferingFilterInput,
  type OfferingSortInput,
  OfferingStatus,
  OfferingType,
} from '../../../graphql/__generated__/index';
import { type NestedSortInput } from '../../../graphql/types';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { getGraphqlWhere } from '../hooks/useCalendarQuery.model';
import { tabConfig as filedTabConfig } from '../tabs/FiledOfferingsCalendar';
import { tabConfig as liveTabConfig } from '../tabs/LiveOfferingsCalendar';
import { tabConfig as lockupTabConfig } from '../tabs/LockupExpirationsOfferingsCalendar';
import { tabConfig as myOfferingsTabConfig } from '../tabs/MyOfferingsCalendar';
import { tabConfig as myOfferingsWithAllocationTabConfig } from '../tabs/MyOfferingsWithAllocationsCalendar';
import { tabConfig as postponedTabConfig } from '../tabs/PostponedOfferingsCalendar';
import { tabConfig as pricedTabConfig } from '../tabs/PricedOfferingsCalendar';
import { FilterValues } from './calendar-filters';
import { CalendarTabType } from './calendar-tabs';
import { customSectorColumn, getColumnsConfig, sectorColumn } from './offeringListColumns';

type SortModelItem = {
  orderBy: string;
  orderByType: string;
};

export function getSortingModel({ orderBy, orderByType }: SortModelItem): OfferingSortInput {
  const direction = orderByType.toUpperCase();

  return orderBy
    .split('.')
    .reverse()
    .reduce((sort, key, index) => ({ [key]: index === 0 ? direction : sort }), {});
}

// Recursively extract field name into Json object, preserving gql schema structure
export const fieldNameToJson = (acc: Object, input: string[], index: number) => {
  if (index >= input.length) {
    return '';
  }
  const item = input[index];
  acc[item] = fieldNameToJson(acc[item] ?? {}, input, index + 1);
  return acc;
};

// We need to query these fields for calculated fields to work
export const defaultBaseGqlFields = {
  status: '',
  attributes: {
    publicFilingDate: '',
    firstTradeDate: '',
    postponedDate: '',
    pricingDate: '',
    lockUpExpirationDate: '',
  },
};

// Field ids not related to actual gql fields has to be filtered out of gql field selection
export const nonGqlFieldIds = [
  'priceRangeLivePricedColumn', // composed from multiple fields
  'sellingRestrictionColumn', // composed from multiple fields
] as string[];

// Field ids not wholly specified via `columnOptions` since part of it is used in `operation`s
export const gqlFieldIdReplaces = {
  offeringNotes: 'offeringNotes.note',
  ioiNotes: 'ioiNotes.note',
  managers: 'managers.name',
};

// Field ids not asked for via `columnOptions` but used in json path `filter`s
export const jsonPathFilterFieldIds = [
  'status',
  'type',
  'attributes.publicFilingDate',
  'attributes.firstTradeDate',
  'attributes.postponedDate',
  'attributes.pricingDate',
  'attributes.lockUpExpirationDate',
  // private data fields
  'userOfferings.userId',
  'ioiNotes.note',
  'allocations.id',
  'allocations.id',
  'indicationsOfInterest.id',
  'fundAllocations.id',
  'fundIndicationsOfInterest.id',
  'fundIndicationsOfInterest.offeringId',
];

// Field ids used by the Fund IOIs sheet (added when `includeFundIois` true)
export const fundIoiSheetFieldIds = [
  'pricingDate',
  'attributes.priceUsd',
  'attributes.totalUnderwritingSecurities',
  'issuer.name',
  'issuer.primarySymbol',
  'fundAllocations.fund.name',
  'fundAllocations.fundAttributes.allocation',
  'fundAllocations.fundAttributes.ioiShares',
  'fundAllocations.fundAttributes.fillRate',
  'fundAllocations.fundAttributes.pctAllocation',
  'fundAllocations.fundAttributes.allocationInvestment',
];

type ColIdName = { colId: string; colName: string };
export const rangeColumns: Record<string, [ColIdName, ColIdName]> = {
  'attributes.priceRangeLow': [
    {
      colId: 'attributes.priceRangeLow',
      colName: 'Price Range Low',
    },
    {
      colId: 'attributes.priceRangeHigh',
      colName: 'Price Range High',
    },
  ],
  priceRangeLivePricedColumn: [
    {
      colId: 'attributes.priceRangeLow',
      colName: 'Price Range Low',
    },
    {
      colId: 'attributes.priceRangeHigh',
      colName: 'Price Range High',
    },
  ],
  sellingRestrictionColumn: [
    {
      colId: 'attributes.isRule144A',
      colName: 'Rule 144A',
    },
    {
      colId: 'attributes.isRegS',
      colName: 'Reg S',
    },
  ],
};

export const additionalOptions: Record<
  string,
  {
    columnName?: string;
    numberPrecision?: number;
    operation?: { type: string; config: {} };
  }
> = {
  // 'attributes.pricingDate': { columnName: 'Pricing Date' },
  'attributes.exchangeRegionDisplayName': { columnName: 'Region' },
  'attributes.exchangeCountryDisplayName': { columnName: 'Country Display Name' },
  'attributes.latestGrossProceedsTotalUsd': { columnName: 'Size ($)' },
  'attributes.marketCapAtPricingUsd': { columnName: 'Market Cap ($)' },
  'attributes.latestOfferPriceUsd:': { columnName: 'Offer Price ($)' },
  'attributes.pricingDate': { columnName: 'Pricing Date' }, // named "Expected Pricing Date" on some tabs
  'attributes.pctToLastTrade': { columnName: 'Discount to Last' },
  'attributes.pctOfferTo1Day': { columnName: 'Offer to 1 Day' },
  'attributes.pctOfferToCurrent': { columnName: 'Offer to Current' },
  managers: {
    operation: {
      type: 'delimit',
      config: {
        filter: '$[*].name',
        delimiter: ',',
      },
    },
  },
};

const currencyCol = {
  colId: 'attributes.pricingCurrency',
  colName: 'Currency',
};
const offerPriceCol = {
  colId: 'attributes.latestOfferPrice',
  colName: 'Offer Price',
  tabs: [CalendarCategory.LIVE],
};

const addedColumnsAfter: Record<
  string,
  { colId: string; colName: string; tabs?: CalendarCategory[] }[]
> = {
  'attributes.typeDisplayName': [
    {
      colId: 'attributes.securityTypeDisplayName',
      colName: 'Security Type',
    },
  ],
  'attributes.exchangeRegionDisplayName': [
    {
      colId: 'attributes.exchangeCountry',
      colName: 'Country Code',
    },
  ],
  'attributes.pricingDate': [currencyCol, offerPriceCol], // Live, Lockups, MyOfferings
  'attributes.firstTradeDate': [currencyCol, offerPriceCol], // Priced
  'attributes.publicFilingDate': [currencyCol, offerPriceCol], // Filed
  'attributes.postponedDate': [currencyCol, offerPriceCol], // Postponed
  priceRangeLivePricedColumn: [
    {
      colId: 'attributes.latestCouponTalkPercentageLow',
      colName: 'Coupon Talk Percentage Low',
    },
    {
      colId: 'attributes.latestCouponTalkPercentageHigh',
      colName: 'Coupon Talk Percentage High',
    },
    {
      colId: 'attributes.latestPremiumTalkLowPercentage',
      colName: 'Premium Talk Low Percentage',
    },
    {
      colId: 'attributes.latestPremiumTalkHighPercentage',
      colName: 'Premium Talk High Percentage',
    },
  ],
  'attributes.leftLeadName': [
    {
      colId: 'managers',
      colName: 'Bookrunners',
    },
  ],
};

const extraOptionalColumns: Record<
  string,
  {
    colId: string;
    columnName: string;
    numberPrecision?: number;
    operation?: { type: string; config: {} };
  }
> = {
  offeringNotes: {
    colId: 'offeringNotes',
    columnName: 'Offering Notes',
    operation: {
      type: 'Delimit',
      config: {
        filter: '$[*].note',
        delimiter: '; ',
      },
    },
  },
  ioiNotes: {
    colId: 'ioiNotes',
    columnName: 'IOI Notes',
    operation: {
      type: 'Delimit',
      config: {
        filter: '$[*].note',
        delimiter: '; ',
      },
    },
  },
};

const tabList = [
  CalendarCategory.LIVE,
  CalendarCategory.PRICED,
  CalendarCategory.FILED,
  CalendarCategory.POSTPONED,
  CalendarCategory.LOCK_UP_EXPIRATION,
  CalendarCategory.MY_OFFERINGS,
  CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS,
];

type ColDef = { field: string; label: string };

const getTabColumns = (
  {
    columnsConfig,
    calendarCategory,
  }: {
    columnsConfig: { field: string; label: string }[];
    calendarCategory: CalendarCategory;
  },
  filters: FilterValues
): ColDef[] =>
  getColumnsConfig(columnsConfig, calendarCategory, filters).map(({ field, label }) => ({
    field,
    label,
  }));

const getSheetConfig = ({
  filters,
}: {
  filters: FilterValues;
}): Record<string, { sheetName: string; filter?: string; columns: ColDef[] }> => {
  const now = new Date();
  const ago20y = subYears(now, 20).toISOString().split('T')[0];
  const ago2w = subWeeks(now, 2).toISOString().split('T')[0];
  const future1y = addYears(now, 1).toISOString().split('T')[0];
  const future20y = addYears(now, 20).toISOString().split('T')[0];
  const future2w = addWeeks(now, 2).toISOString().split('T')[0];
  const yearStart = `${now.getUTCFullYear()}-01-01`;
  const lastSaturday = previousSaturday(now).toISOString().split('T')[0];

  return {
    [CalendarCategory.LIVE]: {
      sheetName: 'Live',
      filter: `$[?(@.status == '${OfferingStatus.Live}' && (@.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}' || @.attributes.publicFilingDate == null))]`,
      columns: getTabColumns(liveTabConfig, filters),
    },
    [CalendarCategory.PRICED]: {
      sheetName: 'Priced',
      filter: `$[?(@.status == '${OfferingStatus.Priced}' && (@.attributes.firstTradeDate >= '${ago2w}' && @.attributes.firstTradeDate <= '${future1y}' || @.attributes.firstTradeDate == null && @.type != '${OfferingType.Convertible}'))]`,
      columns: getTabColumns(pricedTabConfig, filters),
    },
    [CalendarCategory.FILED]: {
      sheetName: 'Filed',
      filter: `$[?(@.status == '${OfferingStatus.Filed}' && (@.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future1y}' || @.attributes.publicFilingDate == null))]`,
      columns: getTabColumns(filedTabConfig, filters),
    },
    [CalendarCategory.POSTPONED]: {
      sheetName: 'Postponed',
      filter: `$[?((@.status == '${OfferingStatus.Postponed}' || @.status == '${OfferingStatus.Withdrawn}') && (@.attributes.publicFilingDate >= '${ago20y}' && @.attributes.publicFilingDate <= '${future20y}' || @.attributes.publicFilingDate == null) && @.attributes.postponedDate >= '${yearStart}' && @.attributes.postponedDate <= '${future20y}')]`,
      columns: getTabColumns(postponedTabConfig, filters),
    },
    [CalendarCategory.LOCK_UP_EXPIRATION]: {
      sheetName: 'Lockup',
      filter: `$[?(@.status == '${OfferingStatus.Priced}' && (@.attributes.pricingDate >= '${ago20y}' && @.attributes.pricingDate <= '${future1y}' || @.attributes.pricingDate == null) && @.attributes.lockUpExpirationDate >= '${lastSaturday}' && @.attributes.lockUpExpirationDate <= '${future2w}')]`,
      columns: getTabColumns(lockupTabConfig, filters),
    },
    [CalendarCategory.MY_OFFERINGS]: {
      sheetName: 'My_Offerings',
      filter: `$[?((@.status != '${OfferingStatus.Priced}' || (@.status == '${OfferingStatus.Priced}' && @.attributes.firstTradeDate >= '${ago2w}' && @.attributes.firstTradeDate <= '${future1y}' || @.attributes.firstTradeDate == null && @.type != '${OfferingType.Convertible}')) && (length(@.userOfferings) > 0 || length(@.ioiNotes) > 0 || length(@.allocations) > 0 || length(@.indicationsOfInterest) > 0  || length(@.fundAllocations) > 0 || length(@.fundIndicationsOfInterest) > 0))]`,
      columns: getTabColumns(myOfferingsTabConfig, filters),
    },
    [CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS]: {
      sheetName: 'My_Offerings',
      filter: `$[?((@.status != '${OfferingStatus.Priced}' || (@.status == '${OfferingStatus.Priced}' && @.attributes.firstTradeDate >= '${ago2w}' && @.attributes.firstTradeDate <= '${future1y}' || @.attributes.firstTradeDate == null && @.type != '${OfferingType.Convertible}')) && (length(@.userOfferings) > 0 || length(@.ioiNotes) > 0 || length(@.allocations) > 0 || length(@.indicationsOfInterest) > 0  || length(@.fundAllocations) > 0 || length(@.fundIndicationsOfInterest) > 0))]`,
      columns: getTabColumns(myOfferingsWithAllocationTabConfig, filters),
    },
  };
};

type GetColumnOptionsParams = {
  columns: ColDef[];
  tabId: CalendarCategory;
  extraColumns: (typeof extraOptionalColumns)[string][];
  useCustomSectors?: boolean;
};

export const getColumnOptions = ({
  columns,
  tabId,
  extraColumns,
  useCustomSectors,
}: GetColumnOptionsParams) => {
  let index = 0;

  const columnOptions = columns
    .filter(
      ({ field }) =>
        (field !== sectorColumn.field || !useCustomSectors) &&
        (field !== customSectorColumn.field || useCustomSectors)
    )
    .reduce((acc, item) => {
      const colId = item.field;
      const colName = item.label;

      // add multiple columns to excel if it is a (low - high) range
      if (rangeColumns[colId]) {
        rangeColumns[colId].forEach(rangeItem => {
          const rangeColId = rangeItem.colId.replaceAll('_', '.');
          acc[rangeColId] = {
            columnName: rangeItem.colName,
            displayOrder: index++,
            ...additionalOptions[rangeItem.colId],
          };
        });
      } else {
        acc[colId.replaceAll('_', '.')] = {
          columnName: colName,
          displayOrder: index++,
          ...additionalOptions[colId],
        };
      }

      if (addedColumnsAfter[colId]) {
        const addedColumns = addedColumnsAfter[colId];
        addedColumns.forEach(addedColumn => {
          if (!addedColumn.tabs || addedColumn.tabs.includes(tabId)) {
            acc[addedColumn.colId] = {
              columnName: addedColumn.colName,
              displayOrder: index++,
              ...additionalOptions[addedColumn.colId],
            };
          }
        });
      }

      return acc;
    }, {});

  if (extraColumns.length > 0) {
    extraColumns.forEach(({ colId, ...extraColumn }) => {
      columnOptions[colId] = {
        ...extraColumn,
        displayOrder: index++,
      };
    });
  }

  return columnOptions;
};

type GetExcelDownloadSheetSetupArgs = {
  tabs: CalendarTabType[];
  filters: FilterValues;
  useCustomSectors?: boolean;
  includeOfferingNotes?: boolean;
  includeIoiNotes?: boolean;
};

type SheetDef = {
  sheetName: string;
  filter?: string;
  columnOptions: Record<string, { columnName: string; displayOrder: number }>;
};

/**
 * TBD: decide whether we want to respect selected (visible) columns
 * note supporting that would need both:
 * - implementing column visiblity memoization
 *   (as of now navigating outside a tab resets its column visiblity setup)
 * - rework mechanism for programmatic column appending on a specific place
 *   (currently it depends on having the preceeding column visible)
 */
export const getExcelDownloadSheetSetup = ({
  tabs,
  filters,
  useCustomSectors,
  includeOfferingNotes,
  includeIoiNotes,
}: GetExcelDownloadSheetSetupArgs): SheetDef[] => {
  const sheetConfig = getSheetConfig({ filters });
  const availableTabList = tabList.filter(
    tabId => !!tabs.find(tab => tab.value === tabId && !!tab.isAvailable)
  );

  const extraColumns: (typeof extraOptionalColumns)[string][] = [];
  if (includeOfferingNotes) {
    extraColumns.push(extraOptionalColumns.offeringNotes);
  }
  if (includeIoiNotes) {
    extraColumns.push(extraOptionalColumns.ioiNotes);
  }

  return availableTabList.map(tabId => ({
    sheetName: sheetConfig[tabId].sheetName,
    filter: sheetConfig[tabId].filter,
    columnOptions: getColumnOptions({
      columns: sheetConfig[tabId].columns,
      tabId,
      extraColumns,
      useCustomSectors,
    }),
  }));
};

type GetExcelDownloadArgs = {
  gqlFilterInput?: OfferingFilterInput;
  sheetSetup: SheetDef[];
  sortModel?: SortModelItem[];
  defaultSortModel?: NestedSortInput;
  baseGqlFields?: Object;
  includeFundIoi?: boolean;
};

/**
 * Turn selected fields, filter, and sort model into excel download args
 *
 * Based on `dlgw/components/offerings-report-table` modified for the calendar
 * - defines `sheets` (each calendar tab is exported as a sheet)
 * - expects VirtualizedTable columns (instead of AgGrid)
 */
export const getExcelDownloadArgs = ({
  gqlFilterInput,
  sortModel = [],
  defaultSortModel = {},
  sheetSetup,
  baseGqlFields = defaultBaseGqlFields,
  includeFundIoi,
}: GetExcelDownloadArgs): datalabApi.CalendarOfferingsRequestDto => {
  const allColumnKeys = uniq(
    sheetSetup
      .map(({ columnOptions }) => Object.keys(columnOptions))
      .flat()
      .map(columnKey => gqlFieldIdReplaces[columnKey] ?? columnKey)
      .concat(jsonPathFilterFieldIds)
      .concat(includeFundIoi ? fundIoiSheetFieldIds : [])
  );

  // Recursively turn selected fields into json object, following gql schema structure
  const selectionJson = allColumnKeys.reduce((acc, colId) => {
    const fieldId = colId.replaceAll('.', '_');
    return nonGqlFieldIds.includes(fieldId) ? acc : fieldNameToJson(acc, colId.split('.'), 0);
  }, baseGqlFields);

  // Stringify json object and replace non-gql characters
  const selectionString = JSON.stringify(selectionJson)
    .replaceAll(/"|'|:/gi, '') // remove single ('), double quotes ("), and colon (:)
    .replaceAll(/,/gi, ' '); // replace comma (,) with space
  const selection = selectionString
    .substring(1, selectionString.length - 1) // remove first and last braces ({})
    .replaceAll(/{|}/gi, ' $& '); // add space around braces ({})

  const ago10y = subYears(new Date(), 10).toISOString().split('T')[0];

  // timeout prevention - ignore too old offerings; note this doesn't need to be precise,
  // we only need to cut off enough to not timeout but actual filtering is done on sheet level;
  // also there is going to be per-sheet graphql selection in the future so this becomes obsolete
  const where = {
    ...gqlFilterInput,
    attributes: {
      ...gqlFilterInput?.attributes,
      and: (gqlFilterInput?.attributes?.and || []).concat([
        {
          or: [{ publicFilingDate: { gte: ago10y } }, { publicFilingDate: { eq: null } }],
        },
        {
          or: [{ pricingDate: { gte: ago10y } }, { pricingDate: { eq: null } }],
        },
        {
          or: [{ firstTradeDate: { gte: ago10y } }, { firstTradeDate: { eq: null } }],
        },
      ]),
    },
  };

  const downloadArg = {
    selection,
    arguments: {
      order: [
        sortModel.length > 0 && sortModel[0] ? getSortingModel(sortModel[0]) : defaultSortModel,
      ],
      where,
    },
    sheets: sheetSetup,
    includeFundIois: includeFundIoi || undefined,
  };

  return downloadArg;
};

export type DownloadPayload = {
  tabs: CalendarTabType[];
  order?: { orderBy: string; orderByType: 'asc' | 'desc' | 'descWithNullFirst' };
  filters: FilterValues;
  useCustomSectors?: boolean;
  includeOfferingNotes?: boolean;
  includeIoiNotes?: boolean;
  includeFundIoi?: boolean;
};

// VirtualizedTableWidget.downloadExport params
export type DownloadExportProps = Omit<DownloadPayload, 'order' | 'filter'>;

export const downloadCalendarExport = async ({
  tabs,
  order,
  filters,
  useCustomSectors,
  includeOfferingNotes,
  includeIoiNotes,
  includeFundIoi,
}: DownloadPayload) => {
  const downloadArgs = getExcelDownloadArgs({
    gqlFilterInput: getGraphqlWhere(undefined, filters),
    sortModel: order && [order],
    sheetSetup: getExcelDownloadSheetSetup({
      tabs,
      filters,
      useCustomSectors,
      includeOfferingNotes,
      includeIoiNotes,
    }),
    includeFundIoi,
  });

  const resp: datalabApi.DownloadCalendarOfferingsResponse =
    await datalabApi.downloadCalendarOfferings(downloadArgs, {});

  if (resp.ok && resp.data) {
    saveAs(
      resp.data,
      apiUtil.getFilenameFromContentDisposition(
        resp.headers['content-disposition'],
        'calendar-download.xlsx'
      )
    );
  } else if (resp.ok && !resp.data) {
    throw new Error('Empty data returned!');
  } else {
    throw new Error('Download failed!');
  }
};
