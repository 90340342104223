import { Alert, theme, ToastManager, useDebouncedCalendarRefreshEvent } from '@cmg/common';
import React from 'react';
import { useDispatch } from 'react-redux';

import VirtualizedTableWidget from '../../../obsolete/components/ui/table/VirtualizedTableWidget';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { isDownloadExcelOn } from '../../datalab/model/utils';
import { isFollowingOffering } from '../../shared/follow-offering/isFollowingOffering';
import Spinner from '../../shared/spinner/Spinner';
import CalendarFilter from '../components/CalendarFilter';
import { fetchDatalabOptionsActions, precedentReportsFromCalendarAction } from '../ducks';
import { useCalendar_FollowOfferingMutation } from '../graphql';
import useCalendarQuery, {
  type Calendar_OfferingFieldsFragment,
  type OrderProps,
} from '../hooks/useCalendarQuery';
import { downloadCalendarExport, DownloadExportProps } from '../model/calendar-export';
import { FilterValues } from '../model/calendar-filters';
import { CalendarTabType } from '../model/calendar-tabs';
import {
  useCustomSectorsVisibility,
  useSellingRestrictionVisibility,
} from '../model/column-visibility';
import {
  Column,
  customSectorColumn,
  getColumnsConfig,
  sectorColumn,
  sellingRestrictionColumn,
} from '../model/offeringListColumns';

export type TabProps = {
  filters: FilterValues;
  tabs: CalendarTabType[];
  onTabChange: any;
};

export type Props = TabProps & {
  calendarCategory: CalendarCategory;
  defaultGroupBy: OrderProps['orderBy'];
  defaultGroupByOrderType: OrderProps['orderByType'];
  defaultOrderBy: OrderProps['orderBy'];
  defaultOrderByType: OrderProps['orderByType'];
  columnsConfig: any[];
  staticColumns: Column[];
  defaultVisibleColumns: string[];
};

const BaseDatalabCalendar = ({
  filters,
  tabs,
  onTabChange,
  calendarCategory,
  defaultGroupBy,
  defaultGroupByOrderType,
  defaultOrderBy,
  defaultOrderByType,
  columnsConfig,
  staticColumns,
  defaultVisibleColumns,
}: Props) => {
  const {
    queryResult: { data, loading, error, refetch },
    pagination,
    resetPagination,
    order,
    handleOnReload,
  } = useCalendarQuery({
    calendarCategory,
    filters,
    defaultGroupOrder: { orderBy: defaultGroupBy, orderByType: defaultGroupByOrderType },
    defaultOrder: { orderBy: defaultOrderBy, orderByType: defaultOrderByType },
  });

  const offerings = data?.offerings?.items ?? [];

  const dispatch = useDispatch();

  useDebouncedCalendarRefreshEvent({ callback: () => refetch() });

  React.useEffect(() => {
    dispatch(fetchDatalabOptionsActions.request());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- useMountEffect

  const categoryColumnsConfig = getColumnsConfig(columnsConfig, calendarCategory, filters);

  const { canReadCustomSectors } = useCustomSectorsVisibility();
  const isSectorVisible = canReadCustomSectors && filters.useCustomSectors ? false : true;

  const { canReadConverts, canReadIntl } = useSellingRestrictionVisibility();
  const isSellingRestrictionVisible = canReadConverts && canReadIntl ? true : false;

  const [visibleColumns, setVisibleColumns] = React.useState<string[]>(defaultVisibleColumns);

  const handleOnExport = async (exportProps: DownloadExportProps) => {
    try {
      await downloadCalendarExport({
        ...exportProps,
        tabs,
        order,
        filters,
        useCustomSectors: !isSectorVisible,
      });
      ToastManager.success('Download successfully completed.');
    } catch (error) {
      ToastManager.error(
        'An error has occurred while trying to download your data. Please try again later.'
      );
    }
  };

  const [followOffering] = useCalendar_FollowOfferingMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const handleOnToggleFollowOffering = async (offering: Calendar_OfferingFieldsFragment) => {
    const isFollowing = isFollowingOffering(offering);
    try {
      await followOffering({
        variables: {
          input: {
            offeringId: offering.id,
            isFollowing: !isFollowing,
          },
        },
      });
      ToastManager.success(`Offering ${isFollowing ? 'un-followed' : 'followed'}.`);
    } catch (error) {
      ToastManager.success(
        `An error has occurred while trying to ${
          isFollowing ? 'un-follow' : 'follow'
        } the offering. Please try again later.`
      );
    }
  };

  const handleOnPrecedentFilter = (offering: Calendar_OfferingFieldsFragment) => {
    const { useCustomSectors } = filters;
    dispatch(precedentReportsFromCalendarAction({ offering, useCustomSectors }));
  };

  return (
    <Spinner show={loading}>
      {error && <Alert severity="error">{error.message}</Alert>}
      <VirtualizedTableWidget
        className="widget-offering-list"
        screen="calendar"
        activeTab={tabs.find(tab => tab.value === calendarCategory)}
        tabs={tabs}
        columnsConfig={categoryColumnsConfig.filter(c => {
          if (c === sectorColumn) {
            return isSectorVisible;
          }
          if (c === customSectorColumn) {
            return !isSectorVisible;
          }
          if (c === sellingRestrictionColumn) {
            return isSellingRestrictionVisible;
          }
          return true;
        })}
        staticColumns={staticColumns.map(({ field }) => field)}
        rows={offerings}
        groupBy={defaultGroupBy}
        groupByOrderType={defaultGroupByOrderType}
        orderBy={order.orderBy}
        orderByType={order.orderByType}
        activePage={pagination.activePage}
        itemsPerPage={pagination.itemsPerPage}
        onReload={handleOnReload}
        totalPages={Math.ceil((data?.offerings?.totalCount ?? 0) / pagination.itemsPerPage)}
        metaData={{
          handleFollowClick: handleOnToggleFollowOffering,
          handleOnPrecedentReports: handleOnPrecedentFilter,
        }}
        fillViewport={true}
        renderFilters={() => <CalendarFilter onFilterChange={resetPagination} />}
        visibleColumns={visibleColumns}
        handleToggleViewableFields={setVisibleColumns}
        downloadExport={isDownloadExcelOn() ? handleOnExport : undefined}
        onChangeTab={onTabChange}
        tableWrapperStyles={{ padding: '0px 20px 0px 10px', fontSize: theme.text.size.xs }}
        paginationType="SERVER"
      />
    </Spinner>
  );
};

export default BaseDatalabCalendar;
