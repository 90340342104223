import { Option, ToastManager } from '@cmg/common';
import React from 'react';

import { SortEnumType } from '../../../../../graphql/__generated__/index';
import { SearchFilterHookResult } from '../../../../../graphql/types';
import { useOfferingsFilterForm_LeftLeadsQuery } from '../graphql/__generated__/OfferingsFilterForm_LeftLeads';
import { useCreateLeftleadOptions } from '../OfferingsFilterForm.model';

export const useSearchLeftleads = (): SearchFilterHookResult => {
  const [searchText, setSearchText] = React.useState<string>();
  const { data, error, loading } = useOfferingsFilterForm_LeftLeadsQuery({
    variables: {
      order: { manager: { name: SortEnumType.Asc } },
      where: {
        isLeftLead: { eq: true },
        manager: searchText ? { name: { like: searchText } } : undefined,
      },
    },
  });
  const resolveRef = React.useRef<(value: Option<string>[]) => void>();
  const leftleadOptions = useCreateLeftleadOptions(data?.offeringManagers?.items ?? []);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching leftleads');
    }
    if (!loading && data && resolveRef.current) {
      resolveRef.current(leftleadOptions);
    }
  }, [loading, data, error, leftleadOptions]);

  const loadOptions = React.useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
    return new Promise<Option<string>[]>(resolve => {
      resolveRef.current = resolve;
    });
  }, []);

  return {
    defaultOptions: leftleadOptions,
    loadOptions,
  };
};
